import * as React from "react"
import "./form-alert.css"

function FormAlert({
  status,
  onClick,
}: {
  status: { sent: boolean; message: string }
  onClick?: () => void
}) {
  return (
    <div
      className={
        status.sent
          ? "alert alert-success alert-dismissible fade show"
          : "alert alert-danger alert-dismissible fade show"
      }
      role="alert"
    >
      {status.message}
      <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
        onClick={onClick}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )
}

export default FormAlert
